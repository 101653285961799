var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-page-heading',{attrs:{"title":"Error Pages","subtitle":"All pages in one spot!"},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('b-breadcrumb',{staticClass:"breadcrumb-alt"},[_c('b-breadcrumb-item',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Error Pages")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("All")])],1)]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-default font-w600 mb-0"},[_vm._v("400")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/401"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-amethyst font-w600 mb-0"},[_vm._v("401")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/403"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-flat font-w600 mb-0"},[_vm._v("403")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/404"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-city font-w600 mb-0"},[_vm._v("404")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-modern font-w600 mb-0"},[_vm._v("500")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('router-link',{attrs:{"to":"/errors/503"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('base-block',{attrs:{"tag":"a","content-class":"text-center","rounded":"","link-shadow":"","href":href},on:{"click":navigate}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h1 text-smooth font-w600 mb-0"},[_vm._v("503")]),_c('p',{staticClass:"text-muted"},[_vm._v("Error Page")])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }